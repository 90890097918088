<template>
  <div>
    <EmptyCollectionBlock
      v-if="empty"
      :active-folder="activeFolder"
      :project="project"
      :hidden-item-count="hiddenItemCount"
      :restricted-action-types="restrictedActionTypes"
    />

    <template v-else>
      <EmbeddedItem
        v-for="asset in visibleAssets"
        :key="asset.id"
        :asset="asset"
        :assets="visibleAssets"
        :active-folder="activeFolder"
        :can-select="canSelect"
        :can-replace="canReplace"
        :can-delete="canDelete"
        :can-preview="canPreview"
        :can-rename="canRename"
        :dragger="dragger"
        :show-size="showSize"
        :show-options="showOptions"
        :show-type="showType"
        :show-modified="showModified"
        :restricted-action-types="restrictedActionTypes"
        :action-mode="actionMode"
        :is-selected="isSelectedAsset(asset)"
        :is-focused="isFocusedAsset(asset)"
        :is-multiselected="isMultiselectedAsset(asset)"
        @multiselect-asset="$emit('multiselect-asset', $event)"
        @multideselect-asset="$emit('multideselect-asset', $event)"
        @delete-asset="$emit('delete-asset', $event)"
        @preview-asset="$emit('preview-asset', $event)"
        @rename-asset="$emit('rename-asset', $event)"
        @replace-asset="$emit('replace-asset', $event)"
        @deselect-asset="$emit('deselect-asset', $event)"
        @drag-asset="$emit('drag-asset', $event)"
        @drag-asset-focus="$emit('drag-asset-focus', $event)"
        @drop-asset="$emit('drop-asset', $event)"
        @select-asset="$emit('select-asset', $event)"
        @view-asset="$emit('view-asset', $event)"
        @move-asset="$emit('move-asset', $event)"
      />

      <HiddenBlock
        v-if="hiddenItemCount > 0"
        :size="'xs'"
        :hidden-item-count="hiddenItemCount"
        :restricted-action-types="restrictedActionTypes"
      />
    </template>
  </div>
  <!-- /component -->
</template>

<script>
import layout from './assets-explorer-collection-layout-mixin.js'
const EmbeddedItem = () => import('./assets-explorer-collection-embedded-item.vue')
const HiddenBlock = () => import('./assets-explorer-collection-hidden-block.vue')
const EmptyCollectionBlock = () => import('./assets-explorer-collection-empty-block.vue')

export default {

  components: {
    EmbeddedItem,
    EmptyCollectionBlock,
    HiddenBlock
  },
  mixins: [layout],

  computed: {

    sortDirection() {
      if (this.sortOrder === null || this.sortOrder === '') return ''
      let [, sortDirection] = this.sortOrder.split(':') // only get the 2nd argument from the destructured array
      return sortDirection.toLowerCase()
    },

    sortBy() {
      if (this.sortOrder === null || this.sortOrder === '') return ''
      let [sortBy] = this.sortOrder.split(':')// only get the 1st argument from the destructured array
      return sortBy.toLowerCase()
    }
  },

  methods: {
    sort(method) {
      let direction = 'asc'
      if (this.sortBy === method && this.sortDirection === 'asc') {
        direction = 'desc'
      }

      let sort = method + ':' + direction
      this.$emit('sort-order-change', sort)
    },

    sortIconClass(method) {
      if (this.sortBy !== method) return 'text-gray-500'
      return 'text-gray-800'
    },

    sortIcon(method) {
      if (this.sortBy !== method) return 'sort'
      if (this.sortDirection === 'asc') return 'caret-down'
      return 'caret-up'
    }
  }
}
</script>

<style scoped>
[data-component] {
  width: 100%;
}

.list {
  padding: 1.5rem 1rem;
  width: 100%;
}

.list table {
  /* Bloody Semantic UI */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 0;
  padding-top: 1rem;
}

.list tr > th:first-child {
  padding-left: 1rem !important;
}

.list tr > th:last-child {
  padding-right: 1rem !important;
}

.list-header-sort-item {
  color: #000;
}

.empty-collection {
  color: #999;
  font-size: 0.857rem;
  padding: 0.2rem 1rem;
  text-transform: uppercase;
}

.ui.placeholder {
  margin-left: 2.5rem;
}
.ui.placeholder .line,
.ui.placeholder .image.header::after {
  background: #f7f7f7;
  height: 0.5rem;
}
.ui.placeholder .image.header::after {
  margin-left: 1.5rem;
}
.ui.placeholder .image.header .line {
  margin-left: 1.5rem;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.ui.selectable.table tbody > tr:hover .ui.placeholder .line,
tr:hover .ui.placeholder .image.header::after {
  background: #eaeaea;
}
</style>
