import { findInListByKey } from '@utils/misc'

export default {
  props: {
    project: {
      required: true,
      type: Object
    },
    assets: {
      required: true,
      type: Array
    },
    activeFolder: {
      required: true,
      type: Object
    },
    displayMode: {
      type: String,
      default: 'full'
    },
    canPreview: {
      default: false,
      type: Boolean
    },
    canSelect: {
      default: false,
      type: Boolean
    },
    canReplace: {
      default: false,
      type: Boolean
    },
    canDelete: {
      default: false,
      type: Boolean
    },
    canRename: {
      default: false,
      type: Boolean
    },
    dragger: {
      default: null,
      type: Object
    },
    selectedAsset: {
      default: null,
      type: Object
    },
    sortOrder: {
      default: null,
      type: String
    },
    focusedAsset: {
      default: null,
      type: Object
    },
    multiselectedAssets: {
      default: () => [],
      type: Array
    },
    restrictedActionTypes: {
      type: Array,
      default: () => []
    },
    actionMode: {
      type: String,
      default: 'view'
    }
  },

  methods: {
    isSelectedAsset: function (asset) {
      return this.selectedAsset && asset.id === this.selectedAsset.id
    },

    isFocusedAsset: function (asset) {
      return this.focusedAsset && asset.id === this.focusedAsset.id
    },

    isMultiselectedAsset: function (asset) {
      return findInListByKey(this.multiselectedAssets, 'id', asset.id) !== null
    }
  },

  computed: {
    empty() {
      if (this.totalItems === 0) return true
      return false
    },

    visibleAssets() {
      // No restrictions
      if (!this.hasRestrictedActionTypes) return this.assets

      return this.assets.filter((row) => {
        if (row.type === 'folder') return true // Folders are always visible
        if (row.type === 'pending') return true // An asset moves to a pending type during processing. this stops it from jumping
        if (this.restrictedActionTypes.includes(row.type)) return true

        return false
      })
    },

    hiddenItemCount() {
      return this.totalItems - this.visibleAssets.length
    },

    hasRestrictedActionTypes() {
      return this.restrictedActionTypes.length > 0
    },

    showSelectActionOption() {
      return this.hasRestrictedActionTypes
    },

    totalItems() {
      // A temp holder to stop the empty marker showing up when the assets are still loading
      // Uses the child count from the folder state instead
      if (
        this.$store.getters['assets/loading'] &&
        this.activeFolder.child_count > this.visibleAssets.length
      ) {
        return this.activeFolder.child_count
      }

      return this.visibleAssets.length
    },

    totalLoaded() {
      return this.assets.length
    },

    totalPlaceholders() {
      if (!this.$store.getters['assets/loading']) return 0
      if (
        this.totalItems &&
        this.totalLoaded &&
        this.totalItems > this.totalLoaded
      ) { return this.totalItems - this.totalLoaded }
      return 0
    },

    firstAsset() {
      if (this.assets.length > 0) {
        return this.assets[0]
      }

      return null
    },

    showExtraFields() {
      return this.displayMode === 'full'
    },

    showModified() {
      if (!this.showExtraFields) return false

      if (this.firstAsset) return this.firstAsset.hasOwnProperty('updated_at')
      return true
    },

    showUploadedBy() {
      if (!this.showExtraFields) return false

      if (this.firstAsset) { return this.firstAsset.hasOwnProperty('uploaded_by_name') }
      return true
    },

    showSize() {
      if (!this.showExtraFields) return false

      if (this.firstAsset) return this.firstAsset.hasOwnProperty('size')
      return true
    },

    showOptions() {
      return this.showExtraFields
    },

    showType() {
      return this.showExtraFields
    },

    showComments() {
      return (
        this.$store.getters['app/getFlag']('itemCommentsEnabled') &&
        this.showExtraFields
      )
    },

    showHeaderRow() {
      return this.showExtraFields
    }
  }
}
